<template>
  <nav>
    <div class="content">
      <ul>
        <li v-for="(item, index) in navList" :key="index">
          <template v-if="item.product">
            <a :href="item.to" class="nav-item" :class="item.to.indexOf(routeUrl.path) == 0 && 'li-act'">{{$t('nav.'+item.name)}}<i class="iconfont duozhi-icon-xiangxia"></i></a>
            <div class="nav-product" :style="'width:'+item.product.length * 150 + 'px'">
              <div v-for="(it, i) in item.product" :key="i">
                <a :href="'/product/?type='+it.key">{{it.name}}</a>
                <a :href="'/product/?type='+it.key+'&category='+t.id" v-for="(t, inx) in it.category" :key="inx">{{t.name}}</a>
              </div>
            </div>
          </template>
          <template v-else-if="item.child">
            <a :href="item.child[0].to" class="nav-item" :class="routeUrl.path.indexOf(item.to) == 0 && 'li-act'">{{$t('nav.'+item.name)}}<i class="iconfont duozhi-icon-xiangxia"></i></a>
            <div class="nav-child">
              <ol v-for="(it, i) in item.child" :key="i">
                <router-link class="nav-item" v-waves :to="it.to" :class="routeUrl.path == it.to && 'li-act'">{{$t('nav.'+it.name)}}</router-link>
              </ol>
            </div>
          </template>
          <a v-else class="nav-item" :class="routeUrl.path.indexOf(item.to) == 0 && 'li-act'" v-waves :href="item.to">{{$t('nav.'+item.name)}}</a>
        </li>
      </ul>
    </div>
    <div class="content-mobile" :style="'max-height: ' + (menuShow ? '200vh' : '0px')">
      <ul>
        <li v-for="(item, index) in navList" :key="index">
          <div v-if="item.product || item.child">
            <div class="menu-item">{{$t('nav.'+item.name)}}<i class="iconfont duozhi-icon-xiangxia"></i></div>
            <div class="menu-child" v-if="item.child">
              <div class="child-list">
                <ol v-for="(it, i) in item.child" :key="i">
                  <a :href="it.to" class="nav-item" :class="routeUrl.path == it.to && 'li-act'">{{$t('nav.'+it.name)}}</a>
                </ol>
              </div>
            </div>
            <div class="menu-child" v-if="item.product">
              <div class="product-list">
                <div v-for="(it, i) in item.product" :key="i">
                  <a :href="'/product/?type='+it.key">{{it.name}}</a>
                  <a :href="'/product/?type='+it.key+'&category='+t.id" v-for="(t, inx) in it.category" :key="inx">{{t.name}}</a>
                </div>
              </div>
            </div>
          </div>
          <a :href="item.to" v-else>{{$t('nav.'+item.name)}}</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import product from "@/assets/hypocritical/product.js"
console.log(Object.keys(product).map(key => ({...product[key], key})),"Object.keys(product).map(key => ({...product[key], key}))")
export default {
  data(){
    return {
      navList: [
        {name: "home", to: "/home"},
        {
          name: "about", to: "/about",
          child:[
            {name: "enterpriseIntroduction", to: "/about/enterpriseIntroduction"},
            {name: "honoraryQualifications", to: "/about/honoraryQualifications"},
            {name: "qualityAssurance", to: "/about/qualityAssurance"},
            {name: "companyVideo", to: "/about/companyVideo"},
            {name: "join", to: "/about/join"},
          ]
        },
        {name: "product", to: "/product/?type=intelligentHardware", product: Object.keys(product).map(key => ({...product[key], key}))},
        {name: "solution", to: "/solution"},
        {
          name: "customerService", to: "/customerService",
          child:[
            {name: "afterSaleService", to: "/customerService/afterSaleService"},
            {name: "download", to: "/customerService/download"},
            {name: "commonProblem", to: "/customerService/commonProblem"},
          ]
        },
        {
          name: "consultingTrends", to: "/consultingTrends",
          child: [
            {name: "companyDynamics", to: "/consultingTrends/companyDynamics"},
            {name: "industryDynamics", to: "/consultingTrends/industryDynamics"},
            
          ]  
        },
        {name: "contactUs", to: "/contactUs"},
      ],
      
    }
  },
  computed: {
    routeUrl(){
      return this.$route
    },
    menuShow(){
      return this.$store.state.menuShow
    }
  },
  created(){
    console.log(this.$route)
  }
}
</script>

<style lang="scss" scoped>
nav{
  height: 50px;
  line-height: 50px;
  background: #f0f0f0;
  position: sticky;
  top: 0;
  z-index: 999;
  .content{
    position: relative;
    ul{
      display: flex;
      list-style: none;
      width: 100%;
      margin: 0;
      position: absolute;
      padding: 0;
      line-height: 50px;
      align-items: flex-start;
      li{
        min-width: 80px;
        text-align: center;
        margin: 0 3px;
        position: relative;
        .nav-item{
          color: #777777;
          display: block;
          line-height: 50px;
          padding: 0 20px;
          position: relative;
          z-index: 99;
          transition: none;
          cursor: pointer;
          &::before{
            content: "";
            position: absolute;
            bottom: 2px;
            width: 0;
            height: 2px;
            background: #ffa829;
            left: 50%;
            transition: all 0.5s;
            opacity: 0;
            border-radius: 50%;
          }
        }
        .nav-product{
          box-shadow: rgb(17 12 46 / 15%) -11px -9px 100px 0px;
          position: absolute;
          display: flex;
          max-height: 0;
          overflow: hidden;
          transition: all 0.5s;
          background: white;
          & > div{
            flex: 1;
            & > a{
              display: block;
              color: #777777;
              font-size: 14px;
              line-height: 40px;
              padding: 0 20px;
              text-align: left;
              transition: all .2s;
              cursor: pointer;
              &::before{
                content: "";
                display: inline-block;
                width: 10px;
                height: 10px;
                margin-right: 6px;
                border-radius: 50%;
                background: #868686;
                transition: all .2s;
              }
            }
            & > a:nth-child(1){
              line-height: 50px;
              font-size: 16px;
              font-weight: 600;
              &::before{
                display: none;
              }
            }
            & > a:hover{
              color: #ffa829;
              &::before{
                background: #ffa829;
              }
            }
          }
        }
        .nav-child{
          box-shadow: rgb(17 12 46 / 15%) -11px -9px 100px 0px;
          position: absolute;
          max-height: 0;
          overflow: hidden;
          transition: all 0.5s;
          background: white;
          width: 100%;
        }
        ol{
          background: white;
          padding: 0;
          transition: all 0.5s;
          overflow: hidden;
          .li-act{
            &::before{
              display: none;
            }
          }
        }
        ol:hover > a{
          color: #ffa829;
        }
        .li-act{
          color: #ffa829;
          &::before{
            content: "";
            width: 80%;
            height: 2px;
            left: 10%;
            opacity: 1;
          }
        }
      }
      li:hover{
        & > .nav-item{
          color: #ffa829;
          &::before{
            content: "";
            width: 80%;
            height: 2px;
            left: 10%;
            opacity: 1;
          }
        }
        
        ol, .nav-product, .nav-child{
          max-height: 400px;
        }
      }
    }
  }
  .content-mobile{
    display: none;
    overflow: auto;
    ul{
      padding: 0;
      margin: 0;
      li{
        background: #ccccccba;
        list-style: none;
        font-weight: 600;
        .menu-child{
          max-height: 0;
          transition: all .9s;
          overflow: hidden;
          font-weight: 600;
          .product-list{
            display: flex;
            & > div{
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0 20px;
              & > a{
                font-weight: 600;
                color: #868686;
                &::before{
                  content: "";
                  display: inline-block;
                  width: 10px;
                  height: 10px;
                  margin-right: 6px;
                  border-radius: 50%;
                  background: #868686;
                  transition: all .2s;
                }
              }
              & > a:nth-child(1){
                &::before{
                  display: none;
                }
              }
            }
            & > div:nth-child(1){
              position: relative;
              &::after{
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 1px;
                background: #868686;

              }
            }
          }
          .child-list{
            ol{
              padding: 0;
              a{
                color: #868686;
                font-size: 14px;

              }
            }
          }
        }
      }
      li:hover{
        .menu-item{
          color: #ffa829;
        }
        .menu-child{
          max-height: 500px;
        }

      }
    }
    a{
      color: #36282b;
    }
  }
}

@media screen and (max-width: 600px) {
  nav{
    position: absolute;
    min-height: 0;
    height: auto;
    top: 70px;
    left: 0;
    width: 100%;
    background: #f0f0f0c0;
    overflow: hidden;
    .content{
      display: none;
    }
    .content-mobile{
      display: block!important;
      transition: all .4s;
      transition-timing-function: cubic-bezier(1, -0.05, 0, 0.97);
    }
  }
}
</style>